import axios from "axios";

function createWithAuth(url, options) {
  const instance = axios.create(
    Object.assign(
      {baseURL: process.env.VUE_APP_API_URL + url},
      {
        ...options,
        timeout: 0
      }
    )
  );
  instance.interceptors.request.use(
    config => {
      return config;
    },
    error => {
      return Promise.reject(error.response);
    }
  );
  instance.interceptors.response.use(
    config => {
      return config;
    },
    error => {
      return Promise.reject(error.response);
    }
  );
  return instance;
}

const works = createWithAuth(`/api/v1/works`);
const magazine = createWithAuth(`/api/v1/magazine`);
const career = createWithAuth(`/api/v1/career`);
const code = createWithAuth(`/api/v1/code`);
const contact = createWithAuth(`/api/v1/contact`);
const upload = createWithAuth(`/api/v1/file/upload`);
const fileDown = createWithAuth(`/api/v1/company`, {
  responseType: "blob"
});

function apiFileDown() {
  return fileDown.get(`/`);
}

function apiWorksList(params) {
  return works.get(`/`, {
    params: params
  });
}

function apiWorksDetail(id) {
  return works.get(`/${id}`);
}

function apiPrizeList(params) {
  return works.get(`/prize`, {
    params: params
  });
}

function apiMagazineList(params) {
  return magazine.get(`/`, {
    params: params
  });
}

function apiMagazineDetail(id) {
  return magazine.get(`/${id}`);
}

function apiCareer(data) {
  return career.post(`/`, data);
}

function apiCareerController(data) {
  return career.get(`/`, data);
}

function apiFile(pageName, data) {
  return upload.post(`/${pageName}`, data);
}
function apiContact(data) {
  return contact.post(``, data);
}
function apiCode(upperCode) {
  return code.get(`/${upperCode}`);
}

export {
  apiFileDown,
  apiWorksList,
  apiWorksDetail,
  apiPrizeList,
  apiMagazineList,
  apiMagazineDetail,
  apiCareer,
  apiCareerController,
  apiFile,
  apiContact,
  apiCode
};
